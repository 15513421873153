<template>
  <div class="page-content-inner pt-5 pr-lg-0">
    <div class="section-header pb-0 mt-2 mb-4">
      <div class="section-header-left">
        <h3 class="uk-text-truncate"><i class="uil-gift"></i> {{ $t('gift.shop') }}</h3>
      </div>
    </div>

    <div class="uk-alert-danger bg-soft-danger position_relative" uk-alert v-if="history_errors != null">
      <a class="uk-alert-close" uk-close></a>
      <p style="color: red" class="m-0">{{ history_errors }}</p>
    </div>

    <div class="uk-grid-medium" uk-grid>
      <div class="uk-width-3-5@l uk-width-5-5">
        <div class="section-header pb-0 mb-4">
          <div class="section-header-left">
            <input type="text" v-model="filter.search" :placeholder="$t('gift.search')" />
          </div>

          <div class="section-header-right">
            <select class="selectpicker ml-3" @change="sortOrder" id="orderselectpicker">
              <option value="created_at" data-sort="desc">
                {{ $t("filters.order.latest_added") }}
              </option>
              <option value="score" data-sort="asc">
                {{ $t("gift.filter.highest_point") }}
              </option>
              <option value="score" data-sort="desc">
                {{ $t("gift.filter.lowest_point") }}
              </option>
            </select>
          </div>
        </div>

        <!-- LOADING BOX -->
        <default-loading v-if="gifts_loading || points_loading" :minHeight="'300px'"></default-loading>
        <!-- Gifts -->

        <template v-else>

          <div class="blog-post gift" v-for="gift in formattedGifts">
            <div class="blog-post-thumbnail">
              <div class="blog-post-thumbnail-inner">
                <span class="blog-item-tag">{{ gift.score }} <i class="uil-coins"></i></span>
                <img :src="gift.photo_link" alt="" />
              </div>
            </div>
            <div class="blog-post-content">
              <h3>{{ gift.name }}</h3>
              <a href="javascript:void(0)" @click="submitGift(gift.id, gift.address_required)"
                :class="(user_coin - gift.score < 0) || (gift.stock_quantity < 1) ? 'disabled' : ''"
                class="btn btn-success btn-sm mt-2"><i class="uil-check-circle"></i> {{ $t("gift.get_it") }}</a>
              <div class="mt-3 mb-1" v-if="user_coin - gift.score < 0">
                <span class="d-block">
                  {{ (user_coin - gift.score).toFixed(2) * -1 }} {{ $t('gift.coin') }} <i class="uil-coins"></i>
                  {{ $t("gift.earn_more") }}
                </span>
              </div>
              <div class="mt-3 mb-1">
                <span v-if="gift.stock_quantity < 1">
                  Stokta mevcut değil
                </span>
                <span v-else>
                  {{ gift.stock_quantity }} {{
                    $t("gift.last_stock_quantity") }}
                </span>
              </div>

            </div>
          </div>
          <pagination v-if="pagination &&
            pagination.hasOwnProperty('links')
            " :pagination="pagination" @changePage="setPage($event)"></pagination>

        </template>

      </div>

      <div class="uk-width-expand">
        <div class="
            course-card
            uk-card-hover uk-card-primary
            foruser
            text-center
            mb-4
          ">
          <div class="course-card-body">
            <i class="uil-coins"></i>
            <h3>{{ user_point }} / {{ user_coin }}</h3>
            <h4>{{ $t("gift.point_have") }} / {{ $t("gift.coin_have") }} </h4>
          </div>
          <div class="course-card-footer justify-content-end" v-if="user_point > 0" style="border: 0;">
            <button type="button" class="btn btn-dark" @click="convertToCoin" v-if="!points_loading">
              <i class="uil-coins"></i>
              {{ $t('gift.convert_to_coins') }}
            </button>
          </div>
        </div>

        <div class="uk-card-default rounded uk-card-hover mt-3 p-2">
          <div class="course-card-body">
            <div class="section-header-left">
              <h4 class="uk-text-truncate">
                <i class="uil-user-circle"></i> {{ $t("gift.history") }}
              </h4>
            </div>

            <div class="pt-3 pb-1 text-center">
              <ul class="uk-list-divider mt-2 pr-3">
                <li v-for="item in formattedGiftHistories" class="d-flex justify-content-between align-items-center">
                  <span> <img :src="item.image" class="w-50px h-50px b-r-4px mr-2"> {{ item.name }}</span>
                  <span>{{ item.point }} <i class="uil-coins"></i></span>
                  <span>{{ item.date }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import store from "@/core/services";
import Pagination from "@/assets/components/Pagination";
import DefaultLoading from "@/view/components/loading/DefaultLoading";
import moment from "moment";

import gift_module, {
  BASE_URL as GIFT_BASE_URL,
  MODULE_NAME as GIFT_MODULE_NAME,
  ERROR as GIFT_ERROR,
  ITEMS as GIFT_ITEMS,
  GET_ITEMS as GIFT_GET_ITEMS,
} from "@/core/services/store/gift.module";

import point_module, {
  USER_POINT_URL as USER_POINT_URL,
  USER_COIN_URL as USER_COIN_URL,
  CONVERT_COIN_URL as CONVERT_COIN_URL,
  MODULE_NAME as POINT_MODULE_NAME,
  USER_POINT,
  USER_COIN,
  LOADING as POINT_LOADING,
  GET_USER_POINT,
  GET_USER_COIN,
  CONVERT_TO_COIN
} from "@/core/services/store/point.module";

import gift_history_module, {
  BASE_URL as HISTORY_BASE_URL,
  MODULE_NAME as HISTORY_MODULE_NAME,
  ITEMS as HISTORY_ITEMS,
  GET_ITEMS as HISTORY_GET_ITEMS,
  CREATE_ITEM as HISTORY_CREATE_ITEM,
  ERROR as HISTORY_ERROR,
  LOADING as GIFT_LOADING
} from "@/core/services/store/gift_history.module";
import { MOMENT_DATE_FORMAT_FOR_KT } from "@/core/config/constant";

const _GIFT_MODULE_NAME = GIFT_MODULE_NAME;
const _POINT_MODULE_NAME = POINT_MODULE_NAME;
const _HISTORY_MODULE_NAME = HISTORY_MODULE_NAME;

export default {
  name: "Gifts",
  components: { Swal, Pagination, DefaultLoading },
  data() {
    return {
      status_id: 1,
      pagination: null,
      activeItem: null,
      successMessage: null,
      errorMessages: [],
      page: 1,
      per_page: 5,
      filter: {
        search: null,
        orderBy: "created_at",
        sort: "desc",
      },
    };
  },
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }
    registerStoreModule(_GIFT_MODULE_NAME, gift_module);
    registerStoreModule(_POINT_MODULE_NAME, point_module);
    registerStoreModule(_HISTORY_MODULE_NAME, gift_history_module);
  },
  computed: {
    gifts: {
      get() {
        return store.getters[GIFT_MODULE_NAME + "/" + GIFT_ITEMS];
      },
      set(value) { },
    },
    gifts_loading: {
      get() {
        return store.getters[GIFT_MODULE_NAME + "/" + GIFT_LOADING];
      },
      set(value) { },
    },
    points_loading: {
      get() {
        return store.getters[POINT_MODULE_NAME + "/" + POINT_LOADING];
      },
      set(value) { },
    },
    user_point: {
      get() {
        return store.getters[POINT_MODULE_NAME + "/" + USER_POINT];
      },
      set(value) { },
    },
    user_coin: {
      get() {
        const userCoin = store.getters[POINT_MODULE_NAME + "/" + USER_COIN];
        if (userCoin === "NaN" || userCoin === null || userCoin === undefined) {
          return 0;
        }
        return userCoin.toFixed(2)
      },
      set(value) { },
    },
    gift_history: {
      get() {
        return store.getters[_HISTORY_MODULE_NAME + "/" + HISTORY_ITEMS];
      },
      set(value) { },
    },
    errors: {
      get() {
        return store.getters[GIFT_MODULE_NAME + "/" + GIFT_ERROR];
      },
      set(value) { },
    },
    history_errors: {
      get() {
        return store.getters[HISTORY_MODULE_NAME + "/" + HISTORY_ERROR];
      },
      set(value) { },
    },
    formattedGifts() {
      let results = [];
      if (this.gifts === null || !("data" in this.gifts)) return [];
      this.pagination = this.gifts;
      this.gifts.data.forEach((currentItem) => {
        results.push({
          id: currentItem.id,
          name: currentItem.name,
          description: currentItem.description ? currentItem.description : "-",
          score: currentItem.score,
          stock_quantity: currentItem.stock_quantity,
          used_quantity: currentItem.used_quantity,
          address_required: currentItem.address_required,
          photo_link: currentItem.image_link,
        });
      });

      return results;
    },
    formattedGiftHistories() {
      let results = [];
      if (this.gift_history === null || !("data" in this.gift_history))
        return [];
      this.gift_history.data.forEach((currentItem) => {
        results.push({
          image: currentItem.gift ? currentItem.gift.image_link : "-",
          name: currentItem.name,
          point: currentItem.point,
          date: currentItem.created_at ? moment(currentItem.created_at).format(MOMENT_DATE_FORMAT_FOR_KT) : "-",
        });
      });

      return results;
    },
  },
  methods: {
    getGiftResult() {
      let self = this;
      this.$store.dispatch(GIFT_MODULE_NAME + "/" + GIFT_GET_ITEMS, {
        url: GIFT_BASE_URL,
        filters: {
          like: self.filter.search,
          page: self.page,
          per_page: self.per_page,
          sort: self.filter.sort,
          order_by: self.filter.orderBy,
          status_id: self.status_id,
        },
      });
    },
    convertToCoin() {
      this.$store.dispatch(POINT_MODULE_NAME + "/" + CONVERT_TO_COIN, {
        url: CONVERT_COIN_URL,
      });
    },
    getUserPoint() {
      this.$store.dispatch(POINT_MODULE_NAME + "/" + GET_USER_POINT, {
        url: USER_POINT_URL,
      });
    },
    getUserCoin() {
      this.$store.dispatch(POINT_MODULE_NAME + "/" + GET_USER_COIN, {
        url: USER_COIN_URL,
      });
    },
    getGiftHistoryResult() {
      this.$store.dispatch(HISTORY_MODULE_NAME + "/" + HISTORY_GET_ITEMS, {
        url: HISTORY_BASE_URL,
        filters: {
          page: 1,
          per_page: 10,
          orderBy: "created_at",
          sort: "desc",
          user_gifts: true,
          status_id: 2,
        },
      });
    },
    submitGift(id, address_required) {
      let input = address_required ? "textarea" : "";
      Swal.fire({
        title: (address_required) ? this.$t("gift.where_to") : this.$t("gift.sure_receive_gift"),
        input: input,
        inputAttributes: {
          autocapitalize: "off",
        },
        showCancelButton: true,
        confirmButtonText: this.$t('general.ok'),
        confirmButtonColor: "#ae71ff",
        cancelButtonText: this.$t('general.give_up'),
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((result) => {
        if (result.isConfirmed) {
          let address = result.value && address_required ? result.value : "";
          this.$store
            .dispatch(HISTORY_MODULE_NAME + "/" + HISTORY_CREATE_ITEM, {
              url: HISTORY_BASE_URL,
              contents: {
                gift_id: id,
                is_addres_required: address_required,
                address: result.value
              }
            })
            .then(() => {
              if (!this.history_errors) {
                Swal.fire({
                  confirmButtonText: this.$t('general.ok'),
                  confirmButtonColor: "#ae71ff",
                  title: this.$t('general.congratulation'),
                  html: this.$t('gift.request_created'),
                });
                this.getAllResults();
                return false
              }
            });
        }
      });
    },
    sortOrder(event) {
      let target = event.target;
      this.page = 1;
      this.filter.orderBy = target.value;
      this.filter.sort = target.selectedOptions[0].getAttribute("data-sort");
      this.getAllResults();
    },
    setPage(page) {
      let self = this;
      self.page = page;
      this.getAllResults();
    },
    resetMessages() {
      this.successMessage = null;
      this.errorMessages = [];
    },
    getAllResults() {
      this.getUserPoint();
      this.getUserCoin();
      this.getGiftHistoryResult();
      this.getGiftResult();
    },
  },
  mounted() {
    if (!this.$isGiftVisible) {
      this.$router.push({ name: 'user.index' })
    }
    this.getAllResults();
    $("#orderselectpicker").selectpicker();
  },
  watch: {
    "filter.search": function (val) {
      this.debouncedFilterSearch();
    },
    successMessage: function (val) {
      this.debouncedResetValues();
    },
    errorMessages: function (val) {
      this.debouncedResetValues();
    },
  },
  created: function () {
    this.debouncedFilterSearch = _.debounce(this.getGiftResult, 500);
    this.debouncedResetValues = _.debounce(this.resetMessages, 2000);
  },
};
</script>

<style scoped></style>
